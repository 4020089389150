import { Box, Typography, type SxProps } from '@mui/material'
import { borderRadius, colors, disabledLabelStyle, spacing } from '@orus.eu/pharaoh'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { useLargeScreen } from '../../lib/use-large-screen'
import { HelpIcon } from '../molecules/help-icon'

type LabelVariant = 'basic' | 'card'

export type LabeledControlProps = {
  sx?: SxProps
  disabled?: boolean
  children: ReactNode
  label: string
  variant?: LabelVariant
  /**
   * Adds an info icon with the hint as tooltip
   */
  hint?: string

  className?: string
}

export function WithLabel({
  sx = {},
  children,
  label,
  disabled,
  variant = 'basic',
  hint,
  className,
}: LabeledControlProps): JSX.Element {
  const largeScreen = useLargeScreen()

  const boxSx: SxProps = useMemo(
    () =>
      variant === 'card'
        ? {
            backgroundColor: colors.blue[100],
            borderRadius: borderRadius[20],
            padding: spacing[50],
            display: 'flex',
            flexDirection: 'column',
            gap: spacing[50],
            ...sx,
          }
        : sx,
    [sx, variant],
  )

  const typographySx: SxProps = useMemo(() => {
    const labelSx: SxProps = disabled ? disabledLabelStyle : {}
    return {
      marginBottom: spacing[20],
      display: 'flex',
      gap: spacing[30],
      justifyContent: largeScreen ? undefined : 'space-between',
      alignItems: 'center',
      ...labelSx,
    }
  }, [disabled, largeScreen])

  return (
    <Box sx={boxSx} className={className}>
      <Typography variant="body2Medium" sx={typographySx} component="label">
        <span> {label}</span>
        {hint ? <HelpIcon title={hint} /> : <></>}
      </Typography>
      {children}
    </Box>
  )
}
